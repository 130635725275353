import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { SystemNotification, SystemNotificationTypes } from './model';
import { Guid } from 'shared/lib/guid';
import { DateTime } from 'luxon';
import { defined } from 'shared/lib/checks';
import { enqueueSnackbar } from 'notistack';

class NotificationsStore {
  public showNotifications = true;

  public notifications: SystemNotification[] = [];

  private maxNotifications = 200;

  private confirmClose = false;

  constructor() {
    makeAutoObservable(this);

    void this.saveToLocalStorage();
  }

  public get unreadNotifications() {
    return this.notifications.filter((notification) => !notification.isRead);
  }

  public get unreadNotificationsCount() {
    return this.unreadNotifications.length;
  }

  public get unshownNotifications() {
    return this.notifications.filter((notification) => !notification.isShown);
  }

  public notify(
    type: SystemNotificationTypes,
    title: string,
    subtitle?: string,
    persist = false,
    includeMessageInTitle = false,
    saveToNotifications = true
  ) {
    if (saveToNotifications) {
      runInAction(() => {
        const notifications =
          this.notifications.length === this.maxNotifications ? this.notifications.slice(1) : this.notifications;
        notifications.push({
          id: Guid.create(),
          dateTime: DateTime.now(),
          type,
          title,
          subtitle,
          isRead: false,
          isShown: !this.showNotifications,
        });
        this.notifications = notifications;
      });
    }

    if (this.showNotifications) {
      const message = includeMessageInTitle && subtitle ? `${title}\n${subtitle}` : title;
      enqueueSnackbar(message, { variant: type, preventDuplicate: true, persist });
    }
  }

  public clearAllNotifications() {
    runInAction(() => {
      this.notifications = [];
    });
  }

  public markMessageAsRead(id: Guid) {
    runInAction(() => {
      const notifications = [...this.notifications];
      const notificationId = notifications.findIndex((notification) => notification.id === id);
      notifications.splice(notificationId, 1, { ...defined(notifications[notificationId]), isRead: true });
      this.notifications = notifications;
    });
  }

  public markMessageAsShown(id: Guid) {
    runInAction(() => {
      const notifications = [...this.notifications];
      const notificationId = notifications.findIndex((notification) => notification.id === id);
      notifications.splice(notificationId, 1, { ...defined(notifications[notificationId]), isShown: true });
      this.notifications = notifications;
    });
  }

  public markAllAsRead() {
    runInAction(() => {
      this.notifications = this.notifications.map((notification) => {
        return {
          ...notification,
          isRead: true,
        };
      });
    });
  }

  public toggleShowNotifications() {
    runInAction(() => {
      this.showNotifications = !this.showNotifications;
    });
  }

  public shouldConfirmClose(shouldConfirm: boolean) {
    if (shouldConfirm) {
      if (!this.confirmClose) {
        window.addEventListener('beforeunload', this.handleTabClose);
        this.setConfirmClose(true);
      }
    } else {
      if (this.confirmClose) {
        window.removeEventListener('beforeunload', this.handleTabClose);
        this.setConfirmClose(false);
      }
    }
  }

  private setConfirmClose(confirm: boolean) {
    runInAction(() => {
      this.confirmClose = confirm;
    });
  }

  private async saveToLocalStorage() {
    await makePersistable(this, {
      name: 'Notifications',
      properties: ['notifications', 'showNotifications'],
      storage: window.localStorage,
    });
  }

  private handleTabClose = (event: BeforeUnloadEvent) => {
    event.preventDefault();

    return (event.returnValue = 'Are you sure you want to exit?');
  };
}

export default new NotificationsStore();
