/* eslint-disable */

/**
 * В этом файле надерган код из сырцов mui для генерации лицензии.
 */

// See "precomputation" in notes
const k: any = [];
let i = 0;

for (; i < 64; ) {
  k[i] = 0 | (Math.sin(++i % Math.PI) * 4294967296);
  // k[i] = 0 | (Math.abs(Math.sin(++i)) * 4294967296);
}

export function md5(s: any) {
  const words: any[] = [];
  let b,
    c,
    d,
    j: any = unescape(encodeURI(s)) + '\x80',
    a: any = j.length;
  const h = [(b = 0x67452301), (c = 0xefcdab89), ~b, ~c];

  s = (--a / 4 + 2) | 15;

  // See "Length bits" in notes
  words[--s] = a * 8;

  for (; ~a; ) {
    // a !== -1
    words[a >> 2] |= j.charCodeAt(a) << (8 * a--);
  }

  for (i = j = 0; i < s; i += 16) {
    a = h;

    for (
      ;
      j < 64;
      a = [
        (d = a[3]),
        b +
          (((d =
            a[0] +
            [(b & c) | (~b & d), (d & b) | (~d & c), b ^ c ^ d, c ^ (b | ~d)][(a = j >> 4)] +
            k[j] +
            ~~words[i | ([j, 5 * j + 1, 3 * j + 5, 7 * j][a] & 15)]) <<
            (a = ([7, 12, 17, 22, 5, 9, 14, 20, 4, 11, 16, 23, 6, 10, 15, 21] as any)[4 * a + (j++ % 4)])) |
            (d >>> -a)),
        b,
        c,
      ]
    ) {
      b = a[1] | 0;
      c = a[2];
    }

    // See "Integer safety" in notes
    for (j = 4; j; ) h[--j] += a[j];

    // j === 0
  }

  for (s = ''; j < 32; ) {
    s += (((h as any)[j >> 3] >> ((1 ^ j++) * 4)) & 15).toString(16);
    // s += ((h[j >> 3] >> (4 ^ 4 * j++)) & 15).toString(16);
  }

  return s;
}

export const LICENSE_SCOPES = ['pro', 'premium'] as const;

export type LicenseScope = typeof LICENSE_SCOPES[number];

export const LICENSING_MODELS = [
  /**
   * A license is outdated if the current version of the software was released after the expiry date of the license.
   * But the license can be used indefinitely with an older version of the software.
   */
  'perpetual',
  /**
   * On development, a license is outdated if the expiry date has been reached
   * On production, a license is outdated if the current version of the software was released after the expiry date of the license (see "perpetual")
   */
  'subscription',
] as const;

export type LicensingModel = typeof LICENSING_MODELS[number];

const _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

function utf8Encode(str: string) {
  for (let n = 0; n < str.length; n++) {
    const c = str.charCodeAt(n);

    if (c >= 128) {
      throw new Error('ASCII only support');
    }
  }

  return str;
}

function base64Encode(input: string): string {
  let output = '';
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  let i = 0;

  input = utf8Encode(input);

  while (i < input.length) {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }

    output = output + _keyStr.charAt(enc1) + _keyStr.charAt(enc2) + _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
  }

  return output;
}

const licenseVersion = '2';

export type LicenseDetails = {
  orderNumber: string;
  expiryDate: Date;
  // TODO: to be made required once the store is updated
  scope?: LicenseScope;
  // TODO: to be made required once the store is updated
  licensingModel?: LicensingModel;
};

function getClearLicenseString(details: LicenseDetails) {
  if (details.scope && !LICENSE_SCOPES.includes(details.scope)) {
    throw new Error('MUI: Invalid scope');
  }

  if (details.licensingModel && !LICENSING_MODELS.includes(details.licensingModel)) {
    throw new Error('MUI: Invalid sales model');
  }

  return `O=${details.orderNumber},E=${details.expiryDate.getTime()},S=${details.scope ?? 'pro'},LM=${
    details.licensingModel ?? 'perpetual'
  },KV=${licenseVersion}`;
}

export function generateLicense(details: LicenseDetails) {
  const licenseStr = getClearLicenseString(details);

  return `${md5(base64Encode(licenseStr))}${base64Encode(licenseStr)}`;
}
