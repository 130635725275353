import { Components } from '@mui/material/styles';

export const MuiDialog: Components['MuiDialog'] = {
  defaultProps: {
    PaperProps: {
      elevation: 8,
      style: {
        borderRadius: 0,
      },
    },
  },
  styleOverrides: {
    root: {
      '&.MuiModal-root': {
        display: 'block',
      },
    },
  },
};
