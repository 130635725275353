export function defined<T>(obj: T | undefined | null): T {
  if (obj === undefined || obj === null) {
    throw new Error('Object undefined.');
  }

  return obj;
}

export function isDefined<T>(value: T): value is NonNullable<T> {
  if (typeof value === 'string') {
    return value.length > 0;
  }
  return value !== undefined && value !== null;
}
