import { LicenseInfo } from '@mui/x-license-pro';
import { generateLicense } from '../lib/mui';

export function configMUI() {
  // Просьба не удалять из комментариев ключи.

  // Date: 2100-01-01
  // Order: MUI-123
  // Scope: premium
  // Key: 8de8ae5aa32c23d0a32c7212167f9f3eTz1NVUktMTIzLEU9NDEwMjQ0NDgwMDAwMCxTPXByZW1pdW0sTE09c3Vic2NyaXB0aW9uLEtWPTI=

  // Date: 2030-01-01
  // Order: MUI-456
  // Scope: premium
  // Key: e9fc3f710d9c187f1ea7fc179895f349Tz1NVUktNDU2LEU9MTg5MzQ1NjAwMDAwMCxTPXByZW1pdW0sTE09c3Vic2NyaXB0aW9uLEtWPTI=

  // Date: 2030-01-01
  // Order: MUI-789
  // Scope: premium
  // Key: 5877ac229d68c27f9f39c8bd71c57244Tz1NVUktNzg5LEU9MTg5MzQ1NjAwMDAwMCxTPXByZW1pdW0sTE09c3Vic2NyaXB0aW9uLEtWPTI=

  const key = generateLicense({
    expiryDate: new Date('2100-01-01'),
    orderNumber: 'MUI-123',
    scope: 'premium',
    licensingModel: 'subscription',
  });

  LicenseInfo.setLicenseKey(key);
}
